<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <strong>รายชื่อทีม</strong>
      <button class="btn btn-sm btn-link" @click="addBranch">เพิ่มทีมใหม่</button>
    </div>

    <div class="card">
      <table class="table table-group table-sm table-hover mb-0">
        <thead>
          <tr>
            <th width="20%">รายชื่อ</th>
            <th width="20%">รหัสผู้ใช้</th>
            <th width="20%">หัวหน้าทีม</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="branch in items">
            <td style="vertical-align: middle;">{{ branch.name }}</td>
            <td style="vertical-align: middle;">{{ branch.users[0].username }}</td>
            <td style="vertical-align: middle;">{{ branch.users[0].firstname }}</td>
            <td class="text-right">
              <button class="btn btn-sm btn-link" @click="editBranch(branch)">แก้ไข</button>
            </td>
          </tr>
          <tr v-if="items.length == 0">
            <td colspan="4" class="small text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
    </div>

    <BranchModal :data="data" :is-show="isShowBranchModal" @close="modalClosed" />
  </div>
</template>
<script>
import BranchService from '@/services/branchService.js'
import BranchModal from './components/BranchModal'

const defaultData = {
  _id: null,
  user_id: null,
  name: '',
  username: '',
  password: '',
  firstname: '',
  lastname: '',
  tel: '',
  line_id: ''
}

export default {
  name: 'AdminSettingBranch',
  components: {
    BranchModal
  },
  data() {
    return {
      isShowBranchModal: false,
      data: JSON.parse(JSON.stringify(defaultData)),
      branchs: []
    }
  },
  computed: {
    items() {
      return this.branchs
    }
  },
  methods: {
    loadBranch() {
      BranchService.gets().then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.branchs = response.data
        }
      })
    },
    addBranch() {
      this.data = JSON.parse(JSON.stringify(defaultData))
      this.isShowBranchModal = true
    },
    editBranch(branch) {
      console.log(branch)
      let data = JSON.parse(JSON.stringify(defaultData))
      this.data = {
        ...data,
        _id: branch._id,
        user_id: branch.users[0]._id,
        name: branch.name,
        username: branch.users[0].username,
        password: '',
        firstname: branch.users[0].firstname,
        lastname: branch.users[0].lastname,
        tel: branch.users[0].tel,
        line_id: branch.users[0].line_id
      }
      this.isShowBranchModal = true
    },
    modalClosed(needReload) {
      this.isShowBranchModal = false
      console.log('needReload', needReload)
      if(needReload) {
        this.loadBranch()
      }
    }
  },
  mounted() {
    this.loadBranch()
  }
}
</script>
