<template>
  <b-modal
    id="branch-modal"
    ref="branch-modal"
    hide-footer
    title="ตั้งค่าทีม"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="ชื่อทีม"
      >
        <b-form-input
          v-model="input.name"
          type="text"
          placeholder="ชื่อทีม"
          :state="validate.name.valid"
          required
        ></b-form-input>
        <b-form-invalid-feedback :state="validate.name.valid">{{validate.name.invalidText}}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Username"
      >
        <b-form-input
          v-model="input.username"
          type="text"
          placeholder="รหัสผู้ใช้"
          :state="validate.username.valid"
          required
        ></b-form-input>
        <b-form-invalid-feedback :state="validate.username.valid">{{validate.username.invalidText}}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Password"
      >
        <b-form-input
          v-model="input.password"
          type="password"
          placeholder="รหัสผ่าน"
          :state="validate.password.valid"
          :required="!input._id"
        ></b-form-input>
        <b-form-invalid-feedback :state="validate.password.valid">{{validate.password.invalidText}}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="ชื่อ"
      >
        <b-form-input
          v-model="input.firstname"
          type="text"
          placeholder="ชื่อ"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="นามสกุล"
      >
        <b-form-input
          v-model="input.lastname"
          type="text"
          placeholder="นามสกุล"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="เบอร์โทร"
      >
        <b-form-input
          v-model="input.tel"
          type="text"
          placeholder="เบอร์โทร"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Line ID"
      >
        <b-form-input
          v-model="input.line_id"
          type="text"
          placeholder="Line ID"
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import BranchService from '@/services/branchService.js'
import Swal from 'sweetalert2'

const defaultValidate = {
  name: {
    valid: null,
    invalidText: ''
  },
  username: {
    valid: null,
    invalidText: ''
  },
  password: {
    valid: null,
    invalidText: ''
  }
}

export default {
  name: 'BranchModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    }
  },
  data() {
    return {
      input: {},
      isProcess: false,
      needReload: false,
      validate: JSON.parse(JSON.stringify(defaultValidate))
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data))
      this.validate = JSON.parse(JSON.stringify(defaultValidate))
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
    },
    showModal() {
      this.$refs['branch-modal'].show()
    },
    hideModal() {
      this.$refs['branch-modal'].hide()
    },
    handleSubmit() {
      console.log(JSON.stringify(this.input))
      if(this.input?._id) {
        this.update()
      }else{
        this.save()
      }
    },
    save() {
      if(this.input?.name && this.input?.username && this.input?.password) {
        BranchService.save(this.input)
        .then((response)=>{
          console.log(response)
          if(response.status === 'success') {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }
        })
        .catch((e)=>{
          if(e?.errors) {
            this.validate = JSON.parse(JSON.stringify(defaultValidate))
            const errors = Object.keys(e.errors).reduce((errors, key) => {
              errors[key] = {
                valid: false,
                invalidText: e.errors[key]
              }
              return errors
            }, {})

            this.validate = {
              ...this.validate,
              ...errors
            }
          }

          Swal.fire({
            title: e?.name || 'ผิดพลาด!',
            text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
    },
    update() {
      BranchService.update(this.input._id, this.input)
        .then((response)=>{
          console.log(response)
          if(response.status === 'success') {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }
        })
        .catch((e)=>{
          if(e?.errors) {
            this.validate = JSON.parse(JSON.stringify(defaultValidate))
            const errors = Object.keys(e.errors).reduce((errors, key) => {
              errors[key] = {
                valid: false,
                invalidText: e.errors[key]
              }
              return errors
            }, {})

            this.validate = {
              ...this.validate,
              ...errors
            }
          }

          Swal.fire({
            title: e?.name || 'ผิดพลาด!',
            text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#branch-modal {
  input[type=text] {
    color: blue;
  }
}
</style>
